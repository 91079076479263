button {
  cursor: pointer;
}

.addTrack {
  border: 1px solid white;
  background-color: rgba(40, 49, 60, 0.7);
}

.track.dummy {
  opacity: 0.5;
  filter: blur(4px);
}
.controlBtn {
  border-radius: 3px;
}
